  import React from 'react'
  import Layout from '../../../components/App/Layout'
  import Navbar from '../../../components/App/NavbarRV'
  import Footer from '../../../components/App/FooterRV'
  import KDPImagePage from '../../../assets/images/pages/3-5-0-0.jpg'
  
  const Details = () => {
      return (
          <Layout>
              <Navbar />
              <div>
                  <div className='container'>
                      <div className='page-title-content'>
                          <ul>
<li><a href='/'>Accueil</a></li><li><a href='/paca/'>Trouvez un expert près de chez vous</a></li><li>Expert batiment Marseille</li>
                          </ul>
                      </div>
                  </div>
              </div>
              <section className="services-details-area ptb-100">
                  <div className="container">
                      <div className="row">
                          <div className="col-lg-8 col-md-12">
                              <div className="services-details-desc">
                                
<h1>Expert indépendant en bâtiment à Marseille</h1>

<p>L’équipe RV Expertises, Experts bâtiments indépendants, propose un accompagnement personnalisé pour chacun de ses clients. </p>
<div className='services-details-image'>
  <img src={KDPImagePage} alt="Expert batiment Marseille" />
</div>

<p>Notre équipe est composée d’un expert bâtiment certifié OFIB et d’un ingénieur structure sapiteur.</p>

<p>Votre ouvrage fissure ? Des problèmes récurrents d’humidité ? un sinistre après une sécheresse ou une inondation ? Découvrez nos solutions expertales personnalisées</p>

<p>Quelque soit vos attentes et vos objectifs, nous élaborons des missions d'expertise adaptées à vos besoins.</p>

<h2>Pourquoi faire intervenir un expert en bâtiment ?</h2>

<p>Le recours à un expert bâtiment est souvent nécessaire face à une pathologie du bâtiment (fissures, humidité, malfaçons). </p>

<p>Le domaine de l’expertise s’adresse à des particuliers, entreprises ou assurés qui sont confrontés à une problématique immobilière et ont un besoin spécifique d’accompagnement et d’orientation sur des solutions techniques.</p>

<h2>Prestations du cabinet RV Expertises</h2>

<p>Le Cabinet RV Expertises est un cabinet composé d’Experts et Ingénieurs spécialistes des pathologies de la construction, calcul structure bâtiment et résistance des matériaux.</p>

<p>Nos prestations : </p>

<ul><li>Expertise Construction : fissures, humidité, mur de soutènement, avant acquisition, avant vente, VEFA, CCMI</li>
<li>Expertise d’assuré : contre-expertise, litige assurance, sécheresse, inondation </li>
<li>Assistance Expertise judiciaire : accompagnement du mandant en Expertise judiciaire - conseil technique à Avocat.</li></ul>


<div className="others-option d-flex align-items-center">
    <div className="option-item">
        <a class="default-btn" href="/contact/"><i className="flaticon-right"></i> Nous contacter</a>
    </div>
</div>


                                
                              </div>
                          </div>
                          <div className="col-lg-4 col-md-12">
                              <div className="services-details-info">
                                  <ul className="services-list">
                                      
<li><a href='/paca/expert-batiment-marseille/' className='active'>Expert batiment Marseille</a></li>
                                      
                                  </ul>
                                  <a href='javascript:history.go(-1)'>Retour</a>
                              </div>
                          </div>
                      </div>
                  </div>
              </section>
              <Footer />
          </Layout>
      );
  }

  export default Details